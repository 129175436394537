import React from 'react'
import parse from 'html-react-parser'
import { Link } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'
import { Fade } from 'react-awesome-reveal'

import { MouseParallaxContainer, MouseParallaxChild } from 'react-parallax-mouse'

import {
  Content,
  PartnerSlider,
  heroClasses,
  heroContainerClasses,
  FloatingCircle,
  childStyles,
  TeamSlider,
  TeamSliderCard,
  teamSliderCardImgProps
} from '../Atoms'
import { MakeMore } from '../Sections'
import Layout from '../Layout'

import { labels, localeUrls } from '../../langs/langs'

import hcP from '../../images/half-circle.svg'
import arrP from '../../images/arrow-top-right-green.svg'
import circP from '../../images/circle-green.svg'
import xxxP from '../../images/x-blue.svg'

import euroI from '../../images/euro-blue.svg'
import percI from '../../images/percentage-blue.svg'
import arroI from '../../images/arrow-top-blue.svg'

export const taxTeam = (localeID) => [
  <TeamSliderCard
    localeID={localeID}
    name="Denisa Ružičková"
    subtitle={`${labels[localeID]['PARTNER']} | ${labels[localeID]['TADVISOR']}`}
    phone="+421 232 606 512"
    mail="ruzickova@ccstax.sk"
    degree="Ing."
    cardUrl="https://wpadmin.ccstax.sk/wp-content/uploads/2022/06/Ruzickova-Denisa.vcf"
    key={1}
  >
    <StaticImage
      className="min-w-full min-h-full"
      quality={90}
      src="../../images/team/Ruzickova_DSC_6034 5.jpg"
      alt="person"
    />
  </TeamSliderCard>,
  <TeamSliderCard
    localeID={localeID}
    name="Róbert Janeček"
    subtitle={`${labels[localeID]['PARTNER']} | ${labels[localeID]['TADVISOR']}`}
    phone="+421 232 606 555"
    mobile="+421 (0)911 665 034"
    mail="janecek@ccstax.sk"
    degree="Ing."
    cardUrl="https://wpadmin.ccstax.sk/wp-content/uploads/2022/06/Janecek-Robert.vcf"
    key={2}
  >
    <StaticImage
      className="min-w-full min-h-full"
      quality={90}
      src="../../images/team/Ruzickova_DSC_6034 3.jpg"
      alt="person"
    />
  </TeamSliderCard>,
  <TeamSliderCard
    localeID={localeID}
    name="Gabriela Lipková"
    subtitle={labels[localeID]['TADVISOR']}
    phone="+421 232 606 551"
    mobile="+421 (0)908 539 508"
    mail="lipkova@ccstax.sk"
    degree="Ing."
    cardUrl="https://wpadmin.ccstax.sk/wp-content/uploads/2022/06/Lipkova-Gabriela.vcf"
    key={3}
  >
    <StaticImage
      className="min-w-full min-h-full"
      quality={90}
      src="../../images/team/Ruzickova_DSC_6034 4.jpg"
      alt="person"
    />
  </TeamSliderCard>,
  <TeamSliderCard
    localeID={localeID}
    name="Andrea Čabáková"
    subtitle={labels[localeID]['TADVISOR']}
    phone="+421 232 606 553"
    mail="cabakova@ccstax.sk"
    degree="JUDr."
    cardUrl="https://wpadmin.ccstax.sk/wp-content/uploads/2022/06/Cabakova-Andrea.vcf"
    key={4}
  >
    <StaticImage
      className="min-w-full min-h-full"
      quality={90}
      src="../../images/team/Ruzickova_DSC_6034 1.jpg"
      alt="person"
    />
  </TeamSliderCard>,
  <TeamSliderCard
    localeID={localeID}
    name="Daniela Gildeinová"
    subtitle={labels[localeID]['TADVISOR']}
    phone="+421 232 606 515"
    mail="gildeinova@ccstax.sk"
    degree="Ing."
    cardUrl="https://wpadmin.ccstax.sk/wp-content/uploads/2022/06/Gildeinova-Daniela.vcf"
    key={5}
  >
    <StaticImage
      className="min-w-full min-h-full"
      quality={90}
      src="../../images/team/Ruzickova_DSC_6034 2.jpg"
      alt="person"
    />
  </TeamSliderCard>
]

const SliderTitle = ({ children, icon }) => (
  <>
    <Fade>
      <img className="w-10 h-10" src={icon} />
    </Fade>
    <h3 className="text-2xl xl:text-3xl font-bold">{children}</h3>
  </>
)

const AboutUs = ({ localeID, location }) => {
  return (
    <Layout location={location} title={labels[localeID]['TEAM']} localeID={localeID}>
      <MouseParallaxContainer className={`${heroClasses} max-h-[52rem]`}>
        <MouseParallaxChild updateStyles={childStyles} factorX={-0.015} factorY={0.015}>
          <FloatingCircle className="bg-green bottom-[15vh] right-[8vw] md:right-[18vw] lg:w-80 lg:h-80 2xl:w-80 2xl:h-80" />
        </MouseParallaxChild>
        <MouseParallaxChild updateStyles={childStyles} factorX={0.01} factorY={0.005}>
          <FloatingCircle className="bg-bggradient -right-[18vw] top-auto bottom-[2vh] md:-right-[4vw] lg:top-[10vh] lg:w-80 lg:h-80 lg:right-[6vw] 2xl:w-80 2xl:h-80" />
        </MouseParallaxChild>
        <MouseParallaxChild updateStyles={childStyles} factorX={-0.03} factorY={0.04}>
          <img src={hcP} className="hidden lg:block h-9 w-9 absolute top-[60%] right-[7vw] z-30" />
        </MouseParallaxChild>
        <Content className={heroContainerClasses}>
          <div className="hcenter relative z-10">
            <div>
              <h1 className="text-5xl 2xl:text-6xl font-bold -tracking-[0.5px]">
                {labels[localeID]['TEAM']}
              </h1>
              <p className="text-lg 2xl:text-xl font-medium py-10">
                {parse(labels[localeID]['TEAM_C'])}
              </p>
              <Link to={localeUrls[localeID]['CTA']} className="button2">
                {labels[localeID]['WORK_W_US']}
              </Link>
            </div>
          </div>
          <div className="hidden sm:flex hcenter justify-center">
            <StaticImage className="w-full" src="../../images/temp.jpg" />
          </div>
        </Content>
        <MouseParallaxChild updateStyles={childStyles} factorX={0.01} factorY={0.005}>
          <img
            src={arrP}
            className="hidden lg:block h-9 w-9 absolute top-[76%] 2xl:top-[70%] right-[45vw] z-30"
          />
        </MouseParallaxChild>
        <MouseParallaxChild updateStyles={childStyles} factorX={-0.005} factorY={0.01}>
          <img
            src={circP}
            className="hidden lg:block h-9 w-9 absolute top-[10%] 2xl:top-[20%] right-[35vw] z-30"
          />
        </MouseParallaxChild>
        <MouseParallaxChild updateStyles={childStyles} factorX={-0.01} factorY={-0.005}>
          <img
            src={xxxP}
            className="hidden lg:block h-9 w-9 absolute top-[20%] 2xl:top-[28%] right-[45vw] z-30"
          />
        </MouseParallaxChild>
      </MouseParallaxContainer>
      <PartnerSlider />
      <div className="py-24 sm:py-32">
        <Content>
          <h2 className="text-4xl sm:text-5xl font-bold -tracking-[0.5px]">
            {labels[localeID]['OUR_TEAM']}{' '}
            <span className="gradtext">{labels[localeID]['SPECIALISTS']}</span>
          </h2>
        </Content>
        <TeamSlider
          arrowClasses="text-blue"
          localeID={localeID}
          title={<SliderTitle icon={percI}>{labels[localeID]['TAXES']}</SliderTitle>}
          titleClasses="pt-6 h-auto"
        >
          {taxTeam(localeID)}
        </TeamSlider>
        <TeamSlider
          arrowClasses="text-blue"
          localeID={localeID}
          title={<SliderTitle icon={arroI}>{labels[localeID]['BILLING']}</SliderTitle>}
          titleClasses="pt-6 h-auto"
        >
          <TeamSliderCard
            localeID={localeID}
            name="Dana Palusová"
            subtitle={labels[localeID]['ACCOUNTANT']}
            phone="+421 232 606 522"
            mail="palusova@ccstax.sk"
            cardUrl="https://wpadmin.ccstax.sk/wp-content/uploads/2022/06/Palusova-Dana.vcf"
          >
            <StaticImage
              className="min-w-full min-h-full"
              quality={90}
              src="../../images/team/Ruzickova_DSC_6034 6.jpg"
              alt="person"
            />
          </TeamSliderCard>
          <TeamSliderCard
            localeID={localeID}
            name="Mária Zimmermannová"
            subtitle={labels[localeID]['ACCOUNTANT']}
            phone="+421 232 606 524"
            mail="zimmermannova@ccstax.sk"
            degree="Ing."
            cardUrl="https://wpadmin.ccstax.sk/wp-content/uploads/2022/06/Zimmermannova-Maria.vcf"
          />
          <TeamSliderCard
            localeID={localeID}
            name="Miriam Girethová"
            subtitle={labels[localeID]['ACCOUNTANT']}
            phone="+421 232 606 525"
            mail="girethova@ccstax.sk"
            degree="Ing."
            cardUrl="https://wpadmin.ccstax.sk/wp-content/uploads/2022/06/Girethova-Miriam.vcf"
          />
          <TeamSliderCard
            localeID={localeID}
            name="Katarína Petrovská"
            subtitle={labels[localeID]['ACCOUNTANT']}
            phone="+421 232 606 526"
            mail="petrovska@ccstax.sk"
            degree="Mgr."
            cardUrl="https://wpadmin.ccstax.sk/wp-content/uploads/2022/06/Petrovska-Katarina.vcf"
          >
            <StaticImage
              className="min-w-full min-h-full"
              quality={90}
              src="../../images/team/Ruzickova_DSC_6034 7.jpg"
              alt="person"
            />
          </TeamSliderCard>
          <TeamSliderCard
            localeID={localeID}
            name="Dominika Horváthová"
            subtitle={labels[localeID]['ACCOUNTANT']}
            phone="+421 232 606 529"
            mail="horvathova@ccstax.sk"
            degree="Ing."
          />
          <TeamSliderCard
            localeID={localeID}
            name="Monika Ježovičová"
            subtitle={labels[localeID]['ACCOUNTANT']}
            phone="+421 232 606 527"
            mail="jezovicova@ccstax.sk"
          />
          <TeamSliderCard
            localeID={localeID}
            name="Marcela Lilková"
            subtitle={labels[localeID]['ACCOUNTANT']}
            phone="+421 232 606 528"
            mail="lilkova@ccstax.sk"
          />
        </TeamSlider>
        <TeamSlider
          arrowClasses="text-blue"
          localeID={localeID}
          title={<SliderTitle icon={euroI}>{labels[localeID]['SALARIES']}</SliderTitle>}
          titleClasses="pt-6 h-auto"
        >
          <TeamSliderCard
            localeID={localeID}
            name="Jana Jenčková"
            subtitle={labels[localeID]['PACCOUNTANT']}
            phone="+421 232 606 533"
            mail="jenckova@ccstax.sk"
            degree="Ing."
            cardUrl="https://wpadmin.ccstax.sk/wp-content/uploads/2022/06/Jenckova-Jana.vcf"
          >
            <StaticImage
              className="min-w-full min-h-full"
              quality={90}
              src="../../images/team/Ruzickova_DSC_6034 8.jpg"
              alt="person"
            />
          </TeamSliderCard>
          <TeamSliderCard
            localeID={localeID}
            name="Martina Georgievová"
            subtitle={labels[localeID]['PACCOUNTANT']}
            phone="+421 232 606 534"
            mail="georgievova@ccstax.sk"
            cardUrl="https://wpadmin.ccstax.sk/wp-content/uploads/2022/06/Georgievova-Martina.vcf"
          >
            <StaticImage
              className="min-w-full min-h-full"
              quality={90}
              src="../../images/team/Ruzickova_DSC_6034 9.jpg"
              alt="person"
            />
          </TeamSliderCard>
          <TeamSliderCard
            localeID={localeID}
            name="Veronika Šubínová"
            subtitle={labels[localeID]['PACCOUNTANT']}
            phone="+421 232 606 535"
            mail="subinova@ccstax.sk"
            cardUrl="https://wpadmin.ccstax.sk/wp-content/uploads/2022/06/Subinova-Veronika.vcf"
          />
          <TeamSliderCard
            localeID={localeID}
            name="Katarína Bartalošová"
            subtitle={labels[localeID]['PACCOUNTANT']}
            phone="+421 232 606 532"
            mail="bartalosova@ccstax.sk"
            degree="Mgr."
          />
          <TeamSliderCard
            localeID={localeID}
            name="Miroslava Zárišová"
            subtitle={labels[localeID]['PACCOUNTANT']}
            phone="+421 232 606 536"
            mail="zarisova@ccstax.sk"
          />
        </TeamSlider>
      </div>
      <MakeMore localeID={localeID} />
    </Layout>
  )
}

export default AboutUs
